<template>
	<section class="inactive-loan">
		<Portal to="hero">
			<StsTitle class="is-desktop" isSmall>Welcome back!</StsTitle>
		</Portal>

		<StsTitle class="is-mobile" isSmall>Welcome back!</StsTitle>

		<StsCard>
			<h1>
				We're sorry.
				<br />
				We do not see any pending loans at this time.
			</h1>
		</StsCard>

		<p><strong>What you can do next</strong></p>

		<aside class="content-container ribbon-grid">
			<a class="ribbon-item" title="Open Online Account Opening" :href="onlineBankingLink" target="_blank">
				<StsOutlinedIcon name="Piggy Bank" :width="iconDimension" :height="iconDimension">
					<MobileBanking />
				</StsOutlinedIcon>
				<span>Open Online Banking</span>
			</a>
			<a class="ribbon-item" title="Open Online Loan Applications" :href="companyWebsiteLink" target="_blank">
				<StsOutlinedIcon name="Desert Financial Logo" :width="iconDimension" :height="iconDimension">
					<DFMark />
				</StsOutlinedIcon>
				<span>Return To Desert Financial</span>
			</a>
		</aside>

		<p>For further assistance, please contact us at <span>602-433-5626</span>, or you can return home.</p>
	</section>
</template>





<script>
import { axios } from '@/utils';
import { Portal } from 'portal-vue';
import { createToastInterface } from 'vue-toastification';

import { StsButton, StsCard, StsOutlinedIcon, StsTitle } from '@/components/SwitchThink';
import { DFMark } from '@/components/SwitchThink/logos';
import { BaseIcon, Handshake, MobileBanking } from '@/components/SwitchThink/icons';
import { getDeviceSize, showSpinner } from '@/mixins';
import { toastConfig } from '@/utils';

const toast = createToastInterface(toastConfig);

export default {
	name: 'InactiveLoan',
	mixins: [getDeviceSize, showSpinner],
	components: {
		Portal,
		StsButton,
		StsCard,
		StsOutlinedIcon,
		StsTitle,
		DFMark,
		BaseIcon,
		Handshake,
		MobileBanking
	},

	data() {
		return {
			onlineBankingLink: '',
			openLoanLink: '',
			companyWebsiteLink: ''
		};
	},
	computed: {
		iconDimension() {
			return this.isMobile ? 75 : 100;
		}
	},
	async created() {
		try {
			this.showSpinner({ loading: true });

			const {
				data: { onlineBankingLink, openLoanLink, companyWebsiteLink }
			} = await axios.get('/api/Configuration/ExternalLinks');

			this.onlineBankingLink = onlineBankingLink;
			this.openLoanLink = openLoanLink;
			this.companyWebsiteLink = companyWebsiteLink;
		} catch (error) {
			toast.error(error);
		} finally {
			this.showSpinner({ loading: false });
		}
	}
};
</script>





<style lang="scss" scoped>
.inactive-loan {
	padding-top: 2.5rem;

	h1 {
		text-align: center;
		font-size: 1.25rem;
		color: var(--primary);
		font-weight: 300;
		margin-left: 1rem;
	}

	p {
		font-size: 0.91rem;
		font-weight: 300;
		color: var(--primary);
		margin: 1.25rem;
		text-align: center;

		strong {
			font-weight: 700;
		}

		span {
			font-weight: 400;
			color: var(--secondary);
		}
	}

	.ribbon-grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 0.5em;
		grid-row-gap: 0.5rem;

		.ribbon-item,
		::v-deep .ribbon-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 0.5rem;
			border-radius: 8px;
			border: 2px solid var(--disabled);
			color: var(--primary);
			font-weight: 300;
			font-size: 0.9rem;
			transition: opacity 1s;
			text-decoration: none;

			&:hover {
				opacity: 0.7;
			}

			span {
				margin-top: 0.5rem;
			}
		}
	}
}
</style>